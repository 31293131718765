@import url('https://fonts.googleapis.com/css?family=Comfortaa');

body {
  margin: 0;
  font-family: 'Comfortaa', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

code {
  font-family: 'Comfortaa', source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root {
  --primary: #f46524;
  --secondary: #ce4617;
  --black: #2d363d;
  --grey: #455159;
  --titleSize: 45px;
  --secondTitleSize: 40px;
  --paddingContain: 40px;
}

.mainPadding {
  padding: 40px 40px 40px 40px;
}

.listShow {
  line-height: 45px;
  font-size: 25px;
}

.containList {
  display: flex;

  p {
    margin-right: 10px;
  }
}

@media screen and (max-width: 500px) {
  .constatationContainer .titleConstatation {
    font-size: 30px;
  }

  .listShow {
    line-height: 25px;
    font-size: 18px;
  }

}