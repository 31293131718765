.mainApp {
    background-color: rgba(128, 125, 125, 0.158);
    padding-left: 3%;
    padding-right: 3%;
  }
  
  .containTitle {
    display: flex;
    justify-content: center;
    background-color: white;
  
    .humTitle {
      color: var(--primary);
      font-size: 3.5rem;
      border: 3px solid var(--primary);
      padding: 0px 30px;
      text-align: center;
      text-transform: uppercase;
      margin: 3%;
      width: fit-content;
      font-weight: 500;
      box-shadow: 5px 5px 10px var(--primary);
      transition: all 0.4s;
      cursor: pointer;
  
      &:hover {
        transform: scale(1.05);
      }
  
      @media screen and (max-width: 900px) {
        font-size: 40px;
      }
    }
  }
  
  .separatorPage {
    margin-top: 3%;
  }
  
  .bgScreen {
    position: fixed;
    z-index: -1;
    width: 100%;
    height: 100%;
  }

  // CheckPoint
  .mainContainerCheckpoint {

    .containerCheckpoint {
      position: fixed;
      z-index: 1;
      display: flex;
      height: 40px;
      background-color: var(--black);
      justify-content: center;
      align-items: center;
      align-self: center;
      bottom: 3%;
      right: 4%;
      padding: 30px;
      border-radius: 5px;
      transition: all 0.3s;
  
      &:hover {
        box-shadow: 2px -3px 15px -2px white;
      }
  
      img {
        height: 30px;
      }
  
      p {
        font-size: 17px;
        padding-left: 15px;
        font-weight: bold;
        margin: 0;
        color: var(--primary);
        cursor: pointer;
        &:hover {
          color: var(--secondary);
        }
      }
    }
  }

  span.closePopup {
    position: absolute;
    bottom: 77%;
    color: var(--black);
    font-size: 16px;
    width: 25px;
    background: var(--primary);
    height: 25px;
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    padding: 0px;
    margin: 0px;
    left: 93%;
    cursor: pointer;
}

