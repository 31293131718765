.howContainer {
    padding: 40px;
    background-color: var(--primary);
    color: white;

    @media screen and (max-width: 500px) {
        padding: 20px;
    }
}

.lineHow {
    width: 100%;
    background-color: white;
    margin-top: 5px;
    margin-bottom: 5px;
}

.leftHowDivider {
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-size: 42px;
    font-weight: bold;
    text-align: center;
    width: 100%;
    margin-left: 5%;
    margin-right: 5%;
    height: 100%;

    p {
        width: 300px;
    }

    @media screen and (max-width: 500px) {
        font-size: 40px;
    }
}

.rightHowDivider {
    font-size: 23px;
    line-height: 35px;
    font-weight: lighter;

    .itemProcedure {
        margin: 15px 20px;
    }

    @media screen and (max-width: 500px) {
        font-size: 18px;
        line-height: 30px;
    }
}