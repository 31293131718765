.constatationContainer {
    background-color: black;
    color: white;

    .titleConstatation {
        font-size: 40px;
        font-weight: bold;
        margin-bottom: 40px;
    }
}

.listTarget {
    margin: 10px 100px;
    max-width: 60%;

    @media screen and (max-width: 900px) {
        max-width: 100%;
        margin: 10px 40px;
    }

    @media screen and (max-width: 500px) {
        margin: 10px 0px;
    }
}

.containBarConstat {
    position: relative;
    bottom: 80px;
}

.containDetailBarConstat:nth-child(1) .barConstat {
    animation: grow1 3s linear backwards;
    @keyframes grow1 {
        from {
            height: 0px;
        }
        to {
            height: 80px;
        }
    }
}

.containDetailBarConstat:nth-child(2) .barConstat {
    animation: grow2 5s linear backwards;
    @keyframes grow2 {
        from {
            height: 0px;
        }
        to {
            height: 120px;
        }
    }
}

.containDetailBarConstat:nth-child(3) .barConstat {
    animation: grow3 7s linear backwards;
    @keyframes grow3 {
        from {
            height: 0px;
        }
        to {
            height: 300px;
        }
    }
}

.containDetailBarConstat:nth-child(4) .barConstat {
    animation: grow4 9s linear backwards;
    @keyframes grow4 {
        from {
            height: 0px;
        }
        to {
            height: 250px;
        }
    }
}

.listBarConstat {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: flex-end;
    align-self: center;
    margin-left: 100px;

    .barConstat {
        width: 80px;
        background-color: white;
    }

    .titleBar {
        margin-top: 10px;
        font-size: 1rem;
        font-weight: bold;
        width: 80px;
    }

    .containerTitleBar {
        align-items: flex-start;
    }
}

.dotBarConstat {
    width: 100%;
    border-bottom: 2px dotted var(--primary);
    margin-top: 5px;
}

@media screen and (max-width: 500px) {
    .constatationContainer .titleConstatation {
      font-size: 30px;
    }
  
    .listShow {
      line-height: 30px;
      font-size: 18px;
    }

    .barConstat {
        width: 50px !important;
        background-color: white;
    }

    .listBarConstat .titleBar {
        font-size: 10px;
    }

    .containerTitleBar {
        align-items: flex-start;
    }

    .listBarConstat .titleBar {
        width: 50px;
    }
    .listBarConstat {
        margin-left: 10px;
    }
}