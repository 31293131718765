.technoContainer {
    padding: 40px;
    background-color: white;

    .titleTechno {
        font-size: var(--titleSize);
        font-weight: bold;
        color: var(--primary);

        @media screen and (max-width: 500px) {
            font-size: 40px;
        }
    }

    .itemTechno {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-top: 5px;
        height: 150px;

        img {
            // height: 80px;
            // max-width: 150px;
            object-fit: contain;

            @media screen and (max-width: 500px) {
                width: 100px !important;
                height: 100px !important;
            }
        }
        

        .titleItemTechno {
            margin-left: 10px;
            margin-right: 10px;
            font-size: 18px;
            color: var(--black);
            font-weight: bold;
        }

        .percentItemTechno {
            color: var(--primary);
            margin-left: 10px;
            font-weight: bold;
        }
    }
}