.containBarTop {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 5px;
}

.dotBar {
    width: 5px;
    height: 5px;
    border-radius: 5px;
    background-color: black;
}

.barTop {
    width: 1px;
    height: 120px;
    background-color: black;
}