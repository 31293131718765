.mainCookie {
    position: fixed;
    bottom: 20px;
    left: 20px;
    background-color: var(--black);
    border-radius: 5px;
    z-index: 20;
    padding: 20px;
    max-height: 400px;
    display: flex;
    max-width: 300px;
    flex-direction: column;
    justify-content: center;
    box-shadow: 1px 5px 10px 1px black;
    animation: slide 0.5s ease-in;

    @keyframes slide {
        from {
            left: -20px
        }
        to {
            left: 20px;
        }
    }

    .imgCookie {
        width: 100px;
        position: absolute;
        z-index: -1;
        top: 10px;
        left: 10px;
        opacity: 0.8;
    }

    p {
        color: whitesmoke;
        text-align: justify;
    }

    .titleCookie {
        color: var(--primary);
        font-weight: 700;
        font-size: 20px;
        text-align: center;
        margin-top: 10px;
    }

    .acceptCookie {
        background-color: var(--primary);
        color: white;
        border-radius: 10px;
        padding: 10px 20px;
        text-align: center;
        cursor: pointer;
        transition: all 0.3s;

        &:hover {
            background-color: var(--secondary);
        }
    }

    .close {
        width: 13px;
        position: absolute;
        top: 10px;
        right: 10px;
        cursor: pointer;
    }
}