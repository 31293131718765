.containCheckPoint {
    background-color: rgba(255, 255, 255, 0.247);
    min-height: 100vh;
}

.mainCheckPoint {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    height: max-content;
}

.containLeftCalendar {
    // width: 100%;
    padding: 3%;
    width: 80%;
    height: 100%;

    .logo {
        width: 200px;
        cursor: pointer;
    }

    .underline {
        width: 150px;
        height: 5px;
        background-color: white;
        position: relative;
        border-radius: 2px;
        bottom: 30px;
        left: 10px;
    }

    .titleCheckpoint {
        font-size: 30px;
        font-weight: 700;
        color: var(--primary);
        // text-shadow: 1px 3px 2px rgba(255, 255, 255, 0.151);

        span {
            font-style: italic;
            font-size: 20px;
        }
    }

    .descriptionCheckpoint {
        padding: 10px 0px;
        text-align: justify;
        color: white;
        font-weight: lighter;
        width: 80%;
    }

    .containDuration {
        display: flex;
        align-items: center;
        color: white;
        padding-bottom: 10px;

        img {
            width: 20px;
        }
        p {
            padding: 0px 8px;
            margin: 0px;
            color: var(--primary);
        }
        span {
            font-weight: lighter;
            font-style: italic;
        }
    }

    .infoCheckpoint {
        padding-top: 10px;
    }
}


.containerCalendar {
    padding: 20px 0px;
    // margin: 20px;
    // width: 80%;
    // margin-left: 15%;
    height: 100vh;
    margin-bottom: 5%;
}

.dailyHourWrapper {
    cursor: pointer !important;
}

.select {
    word-wrap: normal;
    padding: 10px;
    margin-bottom: 20px;
    width: 100%;
    border-radius: 5px;
}

.calendarDayOfWeek {
    background: red;
    color: white;
    font-weight: 600 !important;
    padding: 6px 0px;
    border-radius: 5px;

    div {
        text-align: center !important;
    }
}

.dayCell {
    min-height: 60px !important;
    padding: 5px !important;
}

.dayCell .dayDate {
    text-align: center !important;
    font-weight: bolder !important;
}

.dayCellEvent {
    border-radius: 14px !important;
    font-size: 20px !important;
    height: 20px !important;
    text-align: center !important;
}

.currentDay {
    // border-radius: 50%;
    // color: white;
    // background-color: #ff3e52;
    min-width: 26px !important;
    min-height: 26px !important;
}

.dailyEventWrapper {
    padding: 4px 10px !important;
}

.dailyHourText {
    color: white !important;
}

.txtTimezone {
    position: 'relative';
    top: 5;
    color: white;
    background-color: transparent;
    width: fit-content;
    // border: 1px solid white;
    // padding: 5px 10px;
    margin-top: 20px;
    font-weight: lighter;
}


.mainBtnValidate {
    position: fixed;
    bottom: 3%;
    left: 3%;
}

.containBtnValidate {
    background: var(--black);
    padding: 15px 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;

    img { 
        height: 20px;
        margin-right: 10px;
    }

    button.btnValidate {
        color: white;
        font-size: 15px;
        border-radius: 5px;
        /* width: 50%; */
    }
}

// Popup
.modal-content {
    background: var(--black) !important;
    color: white !important;
}

.modal-title {
    color: var(--primary);
    text-align: center;
    width: 100%;
    text-transform: uppercase;
}

.modal-footer {
    border-top: none !important;
}

.acceptBtn {
    color: white;
    background-color: var(--secondary) !important;
    border: none;

    &:hover {
        background-color: var(--secondary) !important;
        opacity: 0.8;
    }
}


.bodyPopup {
    line-height: 30px;
    
    input {
        width: 100%;
        padding: 5px 10px;
    }

    p span {
        font-weight: lighter;
        display: block;
    }
}

.mainBody {
    text-align: center;
    margin-bottom: 10px;
    span {
        display: block;
        font-weight: bolder;
    }
}

.error {
    color: red;
}