.mainHeader {
    background-color: var(--grey);
    padding: 10px 40px 20px 40px;
    background-image: linear-gradient(rgba(0, 0, 0, 0.11), rgba(0, 0, 0, 0)), 
    url('./../../assets/images/banniere.png');
    background-size: cover;
    box-shadow: 0px -12px 24px 0px #7e7e7e;
    
    .logo {
        width: 120px;
    }

    .bottomLogo {
        width: 45px;
        height: 7px;
        background-color: white;
        position: relative;
        bottom: 20px;
        left: 10px;
    }

    .primaryTitle {
        color: var(--primary);
        font-size: var(--titleSize);
        font-weight: bold;
        margin-top: 20px;
    }

    .secondaryTitle {
        color: white;
        font-size: var(--secondTitleSize);
        font-weight: bold;
        position: relative;
        bottom: 10px;
    }

    .description {
        color: #e1d7d7;
        max-width: 330px;
        margin-top: 30px;
    }

    .contact {
        color: var(--primary);
        opacity: 0.8;
        margin-left: 30%;
        margin-top: 70px;
        margin-bottom: 0px;
    }
}
