.thanksContainer {
    padding: 40px;
    background-color: white;

    .barThanks {
        width: 30px;
        height: 2px;
        background-color: black;
        margin-bottom: 20px;
    }

    .thanksDivider {
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        .leftDivider {
            @media screen and (max-width: 800px) {
                display: flex;
                justify-content: center;
                flex-direction: column;
                align-items: center;

                div:last-child {
                    text-align: center;
                }
            }

        }

        .leftDivider div:first-child {
            color: var(--primary);
            font-size: 3rem;
            font-weight: bold;

            @media screen and (max-width: 500px) {
                font-size: 40px;
            }
        }

        .leftDivider div:last-child {
            font-size: 1.4rem;
            width: 280px;
            margin-bottom: 30px;

            @media screen and (max-width: 500px) {
                font-size: 18px;
            }
        }

        .rightDivider {
            display: flex;
            flex-direction: column;
            align-items: center;

            .boxThanks {
                width: 100%;
                max-width: 400px;
                height: 100px;
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 35px;
                font-weight: bold;
                color: var(--black);

                @media screen and (max-width: 500px) {
                    font-size: 30px;
                }
            }

            .verticalBar {
                height: 90px;
                width: 2px;
                background-color: var(--black);
            }

            .boxThanks.one {
                background-color: rgb(247 225 163);
            }

            .boxThanks.two {
                background-color: rgb(239 190 100);
            }

            .boxThanks.three {
                background-color: rgb(255 189 69);
            }
        }
    }
}