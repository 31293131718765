.whoAreWeContainer {
    background-color: var(--grey);
    color: white;
    background-image: linear-gradient(rgba(0, 0, 0, 0.678), rgba(0, 0, 0, 0.555)), 
    url('./../../assets/images/compute2.jpg');
    background-size: cover;
    background-position: center;

    .mainTitle {
        font-size: 3rem;
        font-weight: bold;
        margin-bottom: 40px;

        @media screen and (max-width: 900px) {
            margin-bottom: 20px;
        }

        @media screen and (max-width: 500px) {
            font-size: 40px;
        }
    }

    .listTarget {
        margin: 10px 100px;
        max-width: 60%;

        @media screen and (max-width: 900px) {
            max-width: 100%;
            margin: 10px 40px;
        }

        @media screen and (max-width: 500px) {
            margin: 10px 0px;
        }
    }
}