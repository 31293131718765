.mainSolution {
    display: flex;
    justify-content: space-between;
    background-color: white;

    .leftSolution {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: var(--titleSize);
        background-color: var(--grey);
        color: white;
        font-weight: bold;
        background-image: linear-gradient(rgba(0, 0, 0, 0.678), rgba(0, 0, 0, 0.555)), 
        url('./../../assets/images/solution.jpg');
        background-size: cover;
        background-position: center;
        min-height: 400px;

        @media screen and (max-width: 500px) {
            font-size: 40px;
        }
    }

    .rightSolution {
        width: 100%;
        background-color: var(--primary);
        padding-top: 20px;
        padding-bottom: 30px;
        color: white;
        padding-left: 40px;
        padding-right: 20px;

        .containList {
            margin: 0px 10px;
        }        
    }

    .listSolution {
        font-size: 1.3rem;
        line-height: 40px;
        font-weight: lighter;
    }
}

.lineSolution {
    width: 50px;
    height: 2px;
    background-color: white;
    margin-top: 20px;
}