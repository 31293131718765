.containEvolutionMobile {
    position: absolute;
    margin: 20px 0px;
    opacity: 0;

    @media screen and (max-width: 700px) {
        position: static;
        opacity: 1;
    }
}

.containEvolution {
    display: block;

    @media screen and (max-width: 700px) {
        display: none;
    }
}

.evolutionContainer {
    background-color: white;
    color: black;
    box-shadow: 2px 4px 5px 4px #4551594a;

    .topBar {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-bottom: 20px;

        div {
            background-color: black;
        }

        .topBarOne {
            height: 3px;
            width: 40px;
        }

        .topBarTwo {
            height: 5px;
            width: 70%;
        }
    }

    .titleEvolution {
        color: var(--primary);
        font-size: var(--titleSize);
        font-weight: 600;
        margin-left: 20px;

        @media screen and (max-width: 500px) {
            font-size: 40px;
        }
    }

    .underTitleEvolution {
        font-size: 20px;
        margin-left: 20px;
    }
}

.detailItemEvolution {
    margin-left: 15px;

    .titleItemEvolution {
        color: var(--primary);
        font-size: 20px;
        font-weight: 600;
        @media screen and (max-width: 700px) {
            font-size: 15px;
        }
    }
    
    .descriptionItemEvolution {
        width: 150px;
        @media screen and (max-width: 700px) {
            font-size: 15px;
        }
    }
}

.itemEvolution {
    display: flex;
    flex-direction: row;
    width: 100%;
}

.firstItemEvolution {
    margin-top: 50px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .itemEvolution:first-child {
        position: relative;
        left: 40px;
        width: 100%;
    }

    .itemEvolution:nth-child(2) {
        justify-content: center;
    }

    .itemEvolution:nth-child(3) {
        justify-content: flex-end;
    }
}

.secondItemEvolution {
    height: 40px;
    display: flex;
    flex-direction: row;
    background-color: var(--grey);
    color: white;
    justify-content: space-between;
    align-items: center;
    padding-left: 10px;
    padding-right: 10px;

    p {
        margin: 0;
        width: 100%;
        padding: 0;
    }

    p:first-child {
        margin-left: 15px;
    }

    p:nth-child(2) {
       text-align: center;
    }

    p:nth-child(3) {
        text-align: right;
        padding-right: 50px;
     }
}

.thirdItemEvolution {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .itemEvolution .detailItemEvolution {
        padding-top: 80px;
    }

    .itemEvolution:first-child {
        justify-content: center;
        padding-left: 50px;
    }

    .itemEvolution:nth-child(2) {
        justify-content: flex-start;
        padding-left: 50px;
    }
}

.containLastDivider {
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;

    .lastDivider {
        height: 2px;
        width: 70%;
        background-color: rgba(0, 0, 0, 0.801);
    }
}

.containEvolutionMobile {
    display: flex;
    flex-direction: row;
    justify-content: center;

    .containYearMobile {
        background-color: var(--grey);
        width: 50px;
        color: white;
        height: 600px;
        display: flex;
        flex-direction: column;
        align-content: center;
        justify-content: space-between;
        align-items: center;
        padding-left: 10px;
        padding-right: 10px;
    
        p:first-child {
            position: relative;
            top: 20px;
        }

        p:last-child {
            position: relative;
            bottom: 30px;
        }
    }
}

.containEvolutionLeftMobile {
    height: 600px;
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: space-around;

    .itemEvolution {
        display: flex;
        flex-direction: column;
    }

    .itemEvolution:first-child {
        position: relative;
        top: 20px;
    }

    .itemEvolution:nth-child(2) {
        position: relative;
        top: 10px;
    }
}

.containEvolutionRightMobile {
    height: 600px;
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: space-between;

    .itemEvolution {
        display: flex;
        flex-direction: column;
    }

    .itemEvolution:first-child {
        position: relative;
        top: 30px;
    }

    .itemEvolution:nth-child(2) {
        position: relative;
        top: 50px;
    }
}