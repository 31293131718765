.containBarHorizontal {
    display: flex;
    flex-direction: row;
    margin-bottom: 5px;
    align-items: center;
}

.dotBar {
    width: 5px;
    height: 5px;
    border-radius: 5px;
    background-color: black;
}

.barHorizontal {
    width: 120px;
    height: 1px;
    background-color: black;
}